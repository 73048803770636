import React from 'react';
import { Link } from 'react-router-dom';
import '../css/Home.css'; // Import the CSS for styling and animations

const Home = ({ user }) => {
    return (
        <div className="home-container">
            <h1>Welcome to <span className="timewise-title animated-title">TimeWise</span> by NasAero</h1>
            <p className="intro-text">Your ultimate timesheet tracker for hassle-free employee time management.</p>

            {!user ? (
                <Link to="/login" className="btn btn-primary login-btn">
                    Log In to Get Started
                </Link>
            ) : (
                <Link to="/timesheet" className="btn btn-success">
                    View Timesheet
                </Link>
            )}
            <br/>
            {user?.role === 'admin' ? (
                <Link to="/admin/timesheets" className="btn btn-success">
                    Admin Panel
                </Link>
            ) : null}
        </div>
    );
};

export default Home;
