import React, { useState, useEffect } from 'react';
import { logTimeIn, logTimeOut, fetchTimesheet, fetchTimesheetForDate, deleteTimeEntry } from '../services/api';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format, startOfWeek, endOfWeek, startOfDay, endOfDay, differenceInMilliseconds } from 'date-fns';
import '../css/Timesheet.css'; // Import CSS for styling
import { toZonedTime } from 'date-fns-tz'; 

const Timesheet = ( {username} ) => {
    const [timesheet, setTimesheet] = useState([]);
    const [timeIn, setTimeIn] = useState(new Date());
    const [timeOut, setTimeOut] = useState(new Date());
    const [selectedTime, setSelectedTime] = useState(new Date()); // Single time state
    const [date, setDate] = useState(new Date());
    const [startDateRange, setStartDateRange] = useState(new Date());
    const [endDateRange, setEndDateRange] = useState(new Date());
    const [filteredTimesheet, setFilteredTimesheet] = useState([]);
    const [error, setError] = useState('');
    const [collapsedSections, setCollapsedSections] = useState({
        logTime: true,
        dateRange: true,
        timesheetEntries: true,
    });

    const [hour, setHour] = useState('12');
    const [minute, setMinute] = useState('00');
    const [period, setPeriod] = useState('AM');


    const today = new Date();
    const [isSettingTimeIn, setIsSettingTimeIn] = useState(true); // Track which time is being set

    useEffect(() => {
        const getTimesheet = async () => {
            try {
                const data = await fetchTimesheet();
                setTimesheet(data);
                setFilteredTimesheet(data);
            } catch (err) {
                setError('Error fetching timesheet data. Please try again later.');
            }
        };
        getTimesheet();
    }, []);

    const toggleCollapse = (section) => {
        setCollapsedSections((prevState) => ({
            ...prevState,
            [section]: !prevState[section],
        }));
    };
    const convertToMilitaryTime = () => {
        // Parse hour and minute as integers if they are not already
        let parsedHour = parseInt(hour, 10);
        let parsedMinute = parseInt(minute, 10);
    
        // Adjust hour for PM times
        let hourIn24Format = period === 'PM' && parsedHour !== 12 ? parsedHour + 12 : parsedHour;
        // Adjust hour for 12 AM (midnight)
        hourIn24Format = period === 'AM' && parsedHour === 12 ? 0 : hourIn24Format;
    
        // Convert hour and minute to 2-digit strings
        const militaryTime = `${String(hourIn24Format).padStart(2, '0')}:${String(parsedMinute).padStart(2, '0')}`;
        return militaryTime;
    };
    
    const handleTimeSelection = (isTimeIn) => {
        const militaryTime = convertToMilitaryTime();
        handleLogTime(isTimeIn, militaryTime); // Assuming handleLogTime accepts military time as a parameter
    };
    const handleLogTime = async (isTimeIn,militaryTime) => {
        // const militaryTime = convertToMilitaryTime();
        const dateFormatted = format(date, 'yyyy-MM-dd');
        
        // If logging Time Out, retrieve the corresponding Time In for validation
        if (!isTimeIn) {
            const existingEntry = await fetchTimesheetForDate(dateFormatted, username);
            if (!existingEntry || !existingEntry.time_in) {
                setError('No Time In found for this date. Please log Time In first.');
                return;
            }
    
            const existingTimeIn = new Date(`${dateFormatted}T${existingEntry.time_in}`);
            const selectedTimeOut = new Date(`${dateFormatted}T${militaryTime}`);
            
            if (selectedTimeOut <= existingTimeIn) {
                setError('Time Out must be later than Time In.');
                return;
            }
    
            // Log Time Out
            try {
                await logTimeOut(dateFormatted, militaryTime);
                // After successful log out, refresh the timesheet data
                const data = await fetchTimesheet();
                setTimesheet(data);
                setFilteredTimesheet(data);
            } catch (err) {
                setError('Error logging time out. Please try again.');
            }
        } else {
            // Log Time In
            try {
                await logTimeIn(dateFormatted, militaryTime);
                // After successful log in, refresh the timesheet data
                const data = await fetchTimesheet();
                setTimesheet(data);
                setFilteredTimesheet(data);
            } catch (err) {
                setError('Error logging time in. Please try again.');
            }
        }
    };
    {/*
    const handleLogTime = async (isTimeIn, militaryTime) => {
        const dateFormatted = format(date, 'yyyy-MM-dd');
        setSelectedTime(new Date(`${dateFormatted}T${militaryTime}`));
        try {
            // If logging Time Out, check if Time In exists
            if (!isTimeIn) {
                const existingEntry = await fetchTimesheetForDate(dateFormatted, username);
                if (!existingEntry || !existingEntry.time_in) {
                    setError('No Time In found for this date. Please log Time In first.');
                    return;
                }
                const existingTimeIn = new Date(`${dateFormatted}T${existingEntry.time_in}`);
                const selectedTimeOut = new Date(`${dateFormatted}T${selectedTime.toTimeString().split(' ')[0]}`);
                
                if (selectedTimeOut < existingTimeIn) {
                    setError('Time Out cannot be earlier than Time In.');
                    return;
                }
            }

            // Proceed to log the time
            setError(''); // Clear any previous errors
            const timeFormatted = format(toZonedTime(selectedTime, 'America/New_York'), 'HH:mm');
    
            if (isTimeIn) {
                console.log(dateFormatted, timeFormatted);
                await logTimeIn(dateFormatted, timeFormatted);
            } else {
                await logTimeOut(dateFormatted, timeFormatted);
            }
    
            const data = await fetchTimesheet();
            setTimesheet(data);
            setFilteredTimesheet(data);
        } catch (err) {
            setError(`Error logging time ${isTimeIn ? 'in' : 'out'}. Please try again.`);
        }
    };
*/}

    const filterTimesheet = () => {
        const filtered = timesheet.filter(entry => {
            const entryDate = new Date(entry.date);
            const entryDateOnly = entryDate.toISOString().split('T')[0]; // Extract only the date part (YYYY-MM-DD)
            const startDateOnly = startDateRange.toISOString().split('T')[0];
            const endDateOnly = endDateRange.toISOString().split('T')[0];
    
            // Compare the date part of the entry with the start and end dates
            return entryDateOnly >= startDateOnly && entryDateOnly <= endDateOnly;
        });
        setFilteredTimesheet(filtered);
    };

    const setCurrentWeek = () => {
        const startOfWorkingWeek = startOfWeek(today, { weekStartsOn: 1 });
        const endOfWorkingWeek = endOfWeek(today, { weekStartsOn: 1 });
        setStartDateRange(startOfWorkingWeek);
        setEndDateRange(endOfWorkingWeek);
    };

    const calculateHoursWorked = (timeIn, timeOut) => {
        if (timeIn && timeOut) {
            const inDate = new Date(timeIn);
            const outDate = new Date(timeOut);
            const differenceInMilliseconds = outDate - inDate;

            if (differenceInMilliseconds > 0) {
                const hours = Math.floor(differenceInMilliseconds / (1000 * 60 * 60)); // Calculate whole hours
                const minutes = Math.floor((differenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60)); // Calculate remaining minutes
                return `${hours}h ${minutes}m`;
            }
        }
        return '0h 0m'; // If no valid time, return 0 hours and 0 minutes
    };

    const calculateTotalHours = () => {
        let totalMinutes = filteredTimesheet.reduce((total, entry) => {
            const timeIn = entry.time_in ? new Date(`${entry.date}T${entry.time_in}`) : null;
            const timeOut = entry.time_out ? new Date(`${entry.date}T${entry.time_out}`) : null;

            if (timeIn && timeOut) {
                const differenceInMilliseconds = timeOut - timeIn;
                return total + differenceInMilliseconds / (1000 * 60); // Add the difference in minutes
            }
            return total;
        }, 0);

        const totalHours = Math.floor(totalMinutes / 60); // Get total hours
        const remainingMinutes = Math.floor(totalMinutes % 60); // Get remaining minutes

        return `${totalHours}h ${remainingMinutes}m`;
    };

    const handleDeleteEntry = async (index, entry) => {
        try {
            // Call deleteTimeEntry with the necessary data
            await deleteTimeEntry(entry.user_id, entry.date, entry.time_in, entry.time_out);
    
            // Update local state to remove the deleted entry from UI
            const updatedTimesheet = filteredTimesheet.filter((_, i) => i !== index);
            setFilteredTimesheet(updatedTimesheet);
        } catch (error) {
            console.error("Failed to delete entry:", error.message);
            alert("Could not delete the entry. Please try again.");
        }
    };

    return (
        <div className="timesheet-container">
            <h2>Timesheet</h2>
            <div className="current-date">
                <p>Today's Date: {format(today, 'yyyy-MM-dd')}</p>
            </div>

            {error && <p className="error-message">{error}</p>}

              {/* Time Log Section */}
              <fieldset className={collapsedSections.logTime ? 'log-time-fieldset' : 'collapsed log-time-fieldset'}>
                    <legend onClick={() => toggleCollapse('logTime')}>Log Time</legend>
                    
                    <label>Date:</label>
                    <DatePicker
                        selected={date}
                        onChange={(date) => setDate(date)}
                        dateFormat="yyyy-MM-dd"
                        portalId="date-picker-portal"
                    />
                    <div className="time-input-group">
                        <label>Hour:</label>
                        <select value={hour} onChange={(e) => setHour(e.target.value)}>
                            {Array.from({ length: 12 }, (_, i) => (
                                <option key={i} value={String(i + 1).padStart(2, '0')}>
                                    {String(i + 1).padStart(2, '0')}
                                </option>
                            ))}
                        </select>

                        <label>Minute:</label>
                        <select value={minute} onChange={(e) => setMinute(e.target.value)}>
                            {Array.from({ length: 60 }, (_, i) => (
                                <option key={i} value={String(i).padStart(2, '0')}>
                                    {String(i).padStart(2, '0')}
                                </option>
                            ))}
                        </select>

                        <label>AM/PM:</label>
                        <select value={period} onChange={(e) => setPeriod(e.target.value)}>
                            <option value="AM">AM</option>
                            <option value="PM">PM</option>
                        </select>
                    </div>
                    <button className="small-button" onClick={() => handleTimeSelection(true)}>Log Time In</button>
                    <button className="small-button" onClick={() => handleTimeSelection(false)}>Log Time Out</button>
                    {/* <label>Time:</label>
                    <DatePicker
                        selected={selectedTime} // Use single time state
                        onChange={setSelectedTime} // Update selected time
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Select Time"
                        dateFormat="HH:mm" // Use military time format
                        portalId="date-picker-portal"
                    />
                    <div className="log-time-buttons">
                        <button 
                            onClick={() => handleLogTime(true)} // Log Time In
                            className="small-button">
                            Log Time In
                        </button>
                        <button 
                            onClick={() => handleLogTime(false)} // Log Time Out
                            className="small-button">
                            Log Time Out
                        </button>
                    </div> */}
                </fieldset>

            {/* Date Range Filter in a fieldset */}
            <fieldset className={collapsedSections.dateRange ? ' date-range-fieldset' : ' collapsed date-range-fieldset'} >
                <legend onClick={() => toggleCollapse('dateRange')}> Filter Timesheet by Date Range</legend>
                <div className="date-picker-group">
                    <div className="date-picker">
                        <label>Start Date:</label>
                        <DatePicker
                            selected={startDateRange}
                            onChange={(date) => setStartDateRange(date)}
                            dateFormat="yyyy-MM-dd"
                            portalId="date-picker-portal"
                        />
                    </div>
                    <div className="date-picker">
                        <label>End Date:</label>
                        <DatePicker
                            selected={endDateRange}
                            onChange={(date) => setEndDateRange(date)}
                            dateFormat="yyyy-MM-dd"
                            portalId="date-picker-portal"
                        />
                    </div>
                </div>
                <div className="button-group">
                    <button onClick={filterTimesheet} className="small-button">Filter Timesheet</button>
                    <button onClick={setCurrentWeek} className="small-button">Show Current Working Week</button>
                </div>
            </fieldset>

            {/* Display Filtered Timesheet in a fieldset */}
            {/* Display Filtered Timesheet in a fieldset */}
            {filteredTimesheet.length > 0 ? (
                <fieldset className={collapsedSections.timesheetEntries ? 'timesheet-entries-fieldset' : 'collapsed timesheet-entries-fieldset'}>
                    <legend onClick={() => toggleCollapse('timesheetEntries')}>Timesheet Entries</legend>
                    <h3>Entries from {format(startDateRange, 'yyyy-MM-dd')} to {format(endDateRange, 'yyyy-MM-dd')}</h3>
                    
                    <div className="table-container"> {/* Scrollable container */}
                        <table>
                            <thead>
                                <tr>
                                    <th scope="col">Date</th>
                                    <th scope="col">Time In</th>
                                    <th scope="col">Time Out</th>
                                    <th scope="col">Hours Worked</th>
                                    <th scope="col">Remove</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredTimesheet.map((entry, index) => {
                                    const timeIn = entry.time_in ? `${entry.date}T${entry.time_in}` : null; // Create a full date-time string
                                    const timeOut = entry.time_out ? `${entry.date}T${entry.time_out}` : null;

                                    const hoursWorked = entry.time_out
                                        ? differenceInMilliseconds(new Date(timeOut), new Date(timeIn)) / (1000 * 60 * 60)
                                        : 0; // Calculate hours worked or set to 0 if no time_out

                                    return (
                                        <tr key={index}>
                                            <td>{entry.date}</td>
                                            <td>{entry.time_in}</td>
                                            <td>{entry.time_out || 'N/A'}</td>
                                            <td>{hoursWorked.toFixed(2)}</td>
                                            <td>
                                            <span 
                                                onClick={() => handleDeleteEntry(index, entry)} 
                                                className="delete-emoji"
                                            >
                                                🗑️ {/* Trash can emoji without button outline */}
                                            </span>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                    
                    <div className="total-hours">
                        <h4>Total Hours for Selected Dates: {calculateTotalHours()} hours</h4>
                    </div>
                </fieldset>
            ) : (
                <p>No timesheet entries found for the selected dates.</p> // Message when no entries are found
            )}

        </div>
    );
};

export default Timesheet;
