import React, { useState } from 'react';
import '../css/ChangePassword.css';  // Import the CSS file
import { changePassword } from '../services/api';

const ChangePassword = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const [currentPasswordVisible, setCurrentPasswordVisible] = useState(false);
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const handlePasswordChange = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setError("New passwords do not match");
      return;
    }

    try {
      const response = await changePassword(currentPassword, newPassword); // Call the changePassword function
      setSuccess(response.message);
    } catch (err) {
      setError(err.message); // Set error message from the exception
    }
  };

  const toggleCurrentPasswordVisibility = () => {
    setCurrentPasswordVisible(!currentPasswordVisible);
  };

  const toggleNewPasswordVisibility = () => {
    setNewPasswordVisible(!newPasswordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  return (
    <div className="change-password">
      <h2>Change Password</h2>
      {error && <p className="error">{error}</p>}
      {success && <p className="success">{success}</p>}
      <form onSubmit={handlePasswordChange}>
        <div className="form-group">
          <label>Current Password:</label>
          <div style={{ position: 'relative' }}>
            <input
              type={currentPasswordVisible ? 'text' : 'password'}
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              required
              className="password-input"
            />
            <button
              type="button"
              onClick={toggleCurrentPasswordVisibility}
              className="toggle-visibility"
            >
              {currentPasswordVisible ? '🙈' : '👁️'}
            </button>
          </div>
        </div>

        <div className="form-group">
          <label>New Password:</label>
          <div style={{ position: 'relative' }}>
            <input
              type={newPasswordVisible ? 'text' : 'password'}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
              className="password-input"
            />
            <button
              type="button"
              onClick={toggleNewPasswordVisibility}
              className="toggle-visibility"
            >
              {newPasswordVisible ? '🙈' : '👁️'}
            </button>
          </div>
        </div>

        <div className="form-group">
          <label>Confirm New Password:</label>
          <div style={{ position: 'relative' }}>
            <input
              type={confirmPasswordVisible ? 'text' : 'password'}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              className="password-input"
            />
            <button
              type="button"
              onClick={toggleConfirmPasswordVisibility}
              className="toggle-visibility"
            >
              {confirmPasswordVisible ? '🙈' : '👁️'}
            </button>
          </div>
        </div>

        <button type="submit" className="btn btn-primary">Change Password</button>
      </form>
    </div>
  );
};

export default ChangePassword;
