import axios from 'axios';

// Set up base URL for API requests
const API_URL = process.env.REACT_APP_API_URL; // Your Flask backend URL

// Login user
export const loginUser = async (username, password) => {
    const response = await axios.post(`${API_URL}/login`, { username, password }, { withCredentials: true });
    return response.data; // Return the response data directly if not using token
};

// Log time in
export const logTimeIn = async (date, timeIn) => {
    // Ensure timeIn is formatted as "HH:mm"
    const response = await axios.post(`${API_URL}/log-time-in`, { date, time_in: timeIn }, { withCredentials: true });
    return response.data; // Return response data if needed
};

// Log time out
export const logTimeOut = async (date, timeOut) => {
    // Ensure timeOut is formatted as "HH:mm"
    const response = await axios.post(`${API_URL}/log-time-out`, { date, time_out: timeOut }, { withCredentials: true });
    return response.data; // Return response data if needed
};

// Fetch employee timesheet
export const fetchTimesheet = async () => {
    const response = await axios.get(`${API_URL}/timesheet`, { withCredentials: true });
    return response.data.timesheet;
};

// Admin: Fetch all timesheets
export const fetchAllTimesheets = async () => {
    const response = await axios.get(`${API_URL}/admin/timesheets`, { withCredentials: true });
    return response.data.timesheets;
};

// Fetch user data
export const fetchUserData = async () => {
    const response = await axios.get(`${API_URL}/user`, { withCredentials: true });
    return response.data; // Assuming this returns { username, role }
};

// Logout user
export const logoutUser = async () => {
    try {
        await axios.post(`${API_URL}/logout`, {}, { withCredentials: true });
    } catch (error) {
        console.error('Error during logout:', error);
        throw new Error('Logout failed.'); // Rethrow the error for handling in App.js
    }
};

// Register user
export const registerUser = async (username, password, role) => {
    const response = await axios.post(`${API_URL}/register`, { username, password, role }, { withCredentials: true });
    return response.data; // Return the response data
};

// Single Date
export const fetchTimesheetForDate = async (date, username) => {
    console.log('Fetching timesheet for date:', date);

    try {
        // Make a GET request to fetch timesheet data for the specified date
        const response = await axios.get(`${API_URL}/api/timesheet/${date}`, {
            params: { username }, // Pass username as a query parameter
            withCredentials: true // Ensure cookies are included for session management
        });

        return response.data; // Return the response data
    } catch (error) {
        // Handle errors gracefully
        if (error.response) {
            // Server responded with a status code outside the 2xx range
            throw new Error(`Error fetching timesheet: ${error.response.status} - ${error.response.data.message || error.message}`);
        } else if (error.request) {
            // Request was made but no response received
            throw new Error('Error fetching timesheet: No response received from server');
        } else {
            // Something happened while setting up the request
            throw new Error('Error fetching timesheet: ' + error.message);
        }
    }
};

// Change password
export const changePassword = async (currentPassword, newPassword) => {
    try {
        const response = await axios.post(`${API_URL}/change-password`, {
            current_password: currentPassword,
            new_password: newPassword,
        }, { withCredentials: true });
        return response.data; // Return the response data
    } catch (error) {
        // Handle errors gracefully
        if (error.response) {
            // Server responded with a status code outside the 2xx range
            throw new Error(`Error changing password: ${error.response.status} - ${error.response.data.message || error.message}`);
        } else if (error.request) {
            // Request was made but no response received
            throw new Error('Error changing password: No response received from server');
        } else {
            // Something happened while setting up the request
            throw new Error('Error changing password: ' + error.message);
        }
    }
};

export const deleteTimeEntry = async (userId, date, timeIn, timeOut) => {
    try {
        const response = await axios.delete(`${API_URL}/delete-time-entry`, {
            data: { user_id: userId, date, time_in: timeIn, time_out: timeOut },
            withCredentials: true
        });
        return response.data; // Return success message or data
    } catch (error) {
        // Handle errors gracefully
        if (error.response) {
            throw new Error(`Error deleting entry: ${error.response.status} - ${error.response.data.message || error.message}`);
        } else if (error.request) {
            throw new Error('Error deleting entry: No response received from server');
        } else {
            throw new Error('Error deleting entry: ' + error.message);
        }
    }
};