import React, { useState, useEffect } from 'react';
import { fetchAllTimesheets, fetchUserData, registerUser } from '../services/api';
import '../css/AdminDashboard.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format, startOfDay, endOfDay } from 'date-fns';

const AdminDashboard = () => {
    const [allTimesheets, setAllTimesheets] = useState({});
    const [isAdmin, setIsAdmin] = useState(false);
    const [newUsername, setNewUsername] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newRole, setNewRole] = useState('employee');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [selectedUser, setSelectedUser] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [filteredEntries, setFilteredEntries] = useState([]);
    const [totalHours, setTotalHours] = useState(0);
    const [allUsersTotalHours, setAllUsersTotalHours] = useState([]);

    useEffect(() => {
        const getAllTimesheets = async () => {
            const data = await fetchAllTimesheets();
            setAllTimesheets(data);
        };

        const checkAdmin = async () => {
            const userData = await fetchUserData();
            if (userData.role === 'admin') {
                setIsAdmin(true);
            }
        };

        getAllTimesheets();
        checkAdmin();
    }, []);

    const handleRegisterUser = async (e) => {
        e.preventDefault();
        setError(null);
        setSuccess(null);

        try {
            await registerUser(newUsername, newPassword, newRole);
            setSuccess('User registered successfully!');
            setNewUsername('');
            setNewPassword('');
            setNewRole('employee');
            const updatedTimesheets = await fetchAllTimesheets();
            setAllTimesheets(updatedTimesheets);
        } catch (error) {
            console.error('Registration failed', error);
            setError('Failed to register user. Please try again.');
        }
    };

    const handleUserChange = (e) => {
        setSelectedUser(e.target.value);
        setFilteredEntries([]);
        setStartDate(null);
        setEndDate(null);
        setTotalHours(0);
    };

    const handleDateRangeChange = () => {
        if (startDate && endDate && selectedUser) {
            const entries = allTimesheets[selectedUser] || [];
    
            const filtered = entries.filter(entry => {
                const entryDateOnly = new Date(entry.date).toISOString().split('T')[0]; // Extract the date part (YYYY-MM-DD)
                const startDateOnly = startDate.toISOString().split('T')[0]; // Start date as YYYY-MM-DD
                const endDateOnly = endDate.toISOString().split('T')[0]; // End date as YYYY-MM-DD
    
                // Compare the date part of the entry with the start and end dates
                return entryDateOnly >= startDateOnly && entryDateOnly <= endDateOnly;
            });
    
            const total = filtered.reduce((acc, entry) => {
                const timeIn = new Date(`${entry.date}T${entry.time_in}`);
                const timeOut = entry.time_out ? new Date(`${entry.date}T${entry.time_out}`) : null;
    
                if (timeOut) {
                    const hours = (timeOut - timeIn) / (1000 * 60 * 60); // Convert milliseconds to hours
                    return acc + hours;
                }
                return acc;
            }, 0);
    
            setFilteredEntries(filtered);
            setTotalHours(total);
        }
    };
    

    const handleAllUsersTotalHours = () => {
        if (startDate && endDate) {
            const allUserTotals = Object.keys(allTimesheets).map((username) => {
                const entries = allTimesheets[username] || [];
    
                const filtered = entries.filter(entry => {
                    const entryDateOnly = new Date(entry.date).toISOString().split('T')[0]; // Extract the date part
                    const startDateOnly = startDate.toISOString().split('T')[0]; // Extract the start date part
                    const endDateOnly = endDate.toISOString().split('T')[0]; // Extract the end date part
    
                    // Compare just the date parts to filter entries within the range
                    return entryDateOnly >= startDateOnly && entryDateOnly <= endDateOnly;
                });
    
                const totalMinutes = filtered.reduce((acc, entry) => {
                    const timeIn = new Date(`${entry.date}T${entry.time_in}`);
                    const timeOut = entry.time_out ? new Date(`${entry.date}T${entry.time_out}`) : null;
    
                    // Only calculate time if both timeIn and timeOut are valid
                    if (timeOut && timeIn) {
                        const minutes = (timeOut - timeIn) / (1000 * 60); // Convert milliseconds to minutes
                        return acc + minutes;
                    }
                    return acc;
                }, 0);
    
                // Convert total minutes to hours and remaining minutes
                const totalHours = Math.floor(totalMinutes / 60); // Whole hours
                const remainingMinutes = Math.floor(totalMinutes % 60); // Remaining minutes
    
                return { username, totalHours: `${totalHours}h ${remainingMinutes}m` };
            });
    
            setAllUsersTotalHours(allUserTotals);
        }
    };
    

    return (
        <div className="admin-dashboard">
            <h2>Admin Dashboard</h2>

            {/* Total Hours for All Users Section */}
            <div className="date-range-picker">
                <h3>Total Hours for All Users</h3>
                <label>Select Date Range:</label>
                <div className="date-picker">
                    <DatePicker 
                        selected={startDate} 
                        onChange={(date) => setStartDate(date)} 
                        selectsStart 
                        startDate={startDate} 
                        endDate={endDate} 
                        placeholderText="Start Date"
                    />
                    <DatePicker 
                        selected={endDate} 
                        onChange={(date) => setEndDate(date)} 
                        selectsEnd 
                        startDate={startDate} 
                        endDate={endDate} 
                        placeholderText="End Date"
                    />
                </div>
                <button onClick={handleAllUsersTotalHours}>Get Total Hours for All Users</button>

                {allUsersTotalHours.length > 0 && (
                    <div className="timesheet-summary">
                        <h4>Total Hours by User</h4>
                        <table>
                            <thead>
                                <tr>
                                    <th>Username</th>
                                    <th>Total Hours</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allUsersTotalHours.map((user, index) => (
                                    <tr key={index}>
                                        <td>{user.username}</td>
                                        <td>{user.totalHours}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>

            {/* Individual User Timesheet Section */}
            <h3>All Employee Timesheets</h3>
            <div className="user-selection">
                <label htmlFor="user-select">Select User:</label>
                <select id="user-select" value={selectedUser} onChange={handleUserChange}>
                    <option value="">--Choose a user--</option>
                    {Object.keys(allTimesheets).map((username) => (
                        <option key={username} value={username}>
                            {username}
                        </option>
                    ))}
                </select>
            </div>
            {selectedUser && (
                <div className="date-range-picker">
                    <label>Select Date Range:</label>
                    <div className="date-picker">
                        <DatePicker 
                            selected={startDate} 
                            onChange={(date) => setStartDate(date)} 
                            selectsStart 
                            startDate={startDate} 
                            endDate={endDate} 
                            placeholderText="Start Date"
                        />
                        <DatePicker 
                            selected={endDate} 
                            onChange={(date) => setEndDate(date)} 
                            selectsEnd 
                            startDate={startDate} 
                            endDate={endDate} 
                            placeholderText="End Date"
                        />
                    </div>
                    <button onClick={handleDateRangeChange}>Get Hours</button>
                </div>
            )}
            {filteredEntries.length > 0 && (
                <div className="timesheet-details">
                    <h4>Timesheet for {selectedUser}</h4>
                    <p>Total Hours: {totalHours.toFixed(2)}</p>
                    <table>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Time In</th>
                                <th>Time Out</th>
                                <th>Hours Worked</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredEntries.map((entry, index) => {
                                const timeIn = new Date(`${entry.date}T${entry.time_in}`);
                                const timeOut = entry.time_out ? new Date(`${entry.date}T${entry.time_out}`) : 'N/A';
                                const hoursWorked = timeOut ? (timeOut - timeIn) / (1000 * 60 * 60) : 0;

                                return (
                                    <tr key={index}>
                                        <td>{timeIn.toLocaleDateString()}</td>
                                        <td>{timeIn.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false })}</td>
                                        <td>{timeOut instanceof Date ? timeOut.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false }) : 'N/A'}</td>
                                        <td>{hoursWorked.toFixed(2)}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            )}

            {/* Add User Form Section */}
            {isAdmin && (
                <div className="add-user-form">
                    <h3>Add New User</h3>
                    <form onSubmit={handleRegisterUser}>
                        <input 
                            type="text" 
                            placeholder="Username" 
                            value={newUsername} 
                            onChange={(e) => setNewUsername(e.target.value)} 
                            required 
                        />
                        <input 
                            type="password" 
                            placeholder="Password" 
                            value={newPassword} 
                            onChange={(e) => setNewPassword(e.target.value)} 
                            required 
                        />
                        <select value={newRole} onChange={(e) => setNewRole(e.target.value)}>
                            <option value="employee">Employee</option>
                            <option value="admin">Admin</option>
                        </select>
                        <button type="submit">Register User</button>
                    </form>
                    {error && <p className="error">{error}</p>}
                    {success && <p className="success">{success}</p>}
                </div>
            )}
        </div>
    );
};

export default AdminDashboard;
