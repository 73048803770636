import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is loaded
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // Ensure Bootstrap JS is loaded
import '../css/Header.css'; // Custom CSS for additional styling

const Header = ({ username, role, onLogout }) => {
    const navigate = useNavigate();

    const handleLogoutClick = async () => {
        await onLogout(); // Call the logout function passed from the App component
        navigate('/login'); // Redirect to the login page after logout
    };

    return (
        <header className="header">
            <nav className="navbar navbar-expand-lg navbar-light bg-primary">
                <div className="container-fluid">
                    <h1 className="navbar-brand">
                        <Link className="header__link" to="/">TimeWise</Link>
                    </h1>
                    <button 
                        className="navbar-toggler" 
                        type="button" 
                        data-bs-toggle="collapse" 
                        data-bs-target="#navbarNav" 
                        aria-controls="navbarNav" 
                        aria-expanded="false" 
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav ms-auto">
                            {username ? (
                                <>
                                    <li className="nav-item">
                                        <Link className="nav-link"  to="/">Welcome, {username}</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/timesheet">Timesheet</Link>
                                    </li>
                                    {role === 'admin' && (
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/admin/timesheets">Admin</Link>
                                        </li>
                                    )}
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/change-password">Change Password</Link>
                                    </li>
                                    <li className="nav-item">
                                        <button className="btn btn-link nav-link" onClick={handleLogoutClick}>Logout</button>
                                    </li>
                                </>
                            ) : (
                                <li className="nav-item">
                                    <Link className="nav-link" to="/login">Login</Link>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    );
};

export default Header;
