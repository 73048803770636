import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Timesheet from './components/Timesheet';
import Login from './components/Login';
import AdminDashboard from './components/AdminDashboard';
import Home from './components/Home'; // Import the Home component
import ChangePassword from './components/ChangePassword';
import { logoutUser } from './services/api';

const App = () => {
    const [user, setUser] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const storedUser = localStorage.getItem('user');
        console.log(storedUser);
        
        if (storedUser) {
            setUser(JSON.parse(storedUser)); // Set user from local storage if exists
        }
    }, []);

    const handleLogin = (userData) => {
        // This function will be called from Login.js after successful login
        setUser(userData); // Directly set the user data
        localStorage.setItem('user', JSON.stringify(userData)); // Store user data in local storage
    };

    const handleLogout = async () => {
        try {
            await logoutUser();  // Ensure the user is logged out from the API
            localStorage.removeItem('user'); // Clear user data from localStorage
            setUser(null); // Update user state
            setError(null); // Clear any error state
        } catch (err) {
            console.error('Logout failed:', err); // Log any logout error
            setError('Logout failed. Please try again.');
        }
    };

    return (
        <Router>
            <Header
                username={user?.username}
                role={user?.role}
                onLogout={handleLogout}
            />
            {error && !user && <div className="alert alert-danger">{error}</div>}
            <Routes>
                <Route path="/" element={<Home user={user} />} /> {/* Home route */}
                <Route path="/timesheet" element={<Timesheet username={user?.username} />} />
                <Route path="/admin/timesheets" element={<AdminDashboard />} />
                <Route path="/change-password" element={<ChangePassword />} /> {/* Change Password route */}
                <Route path="/login" element={<Login onLogin={handleLogin} />} /> {/* Pass handleLogin to Login */}
            </Routes>
        </Router>
    );
};

export default App;
