import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { loginUser, fetchUserData } from '../services/api';  
import '../css/Login.css';  // Import custom CSS for additional styles

const Login = ({ onLogin }) => { // Changed from setUser to onLogin
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null); 
    const [passwordVisible, setPasswordVisible] = useState(false);
    const navigate = useNavigate();
    
    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null); 
        try {
            const token = await loginUser(username, password);
            if (token) {
                localStorage.setItem('token', token); // Store token in localStorage

                const userData = await fetchUserData(); // Fetch user data with the token
                onLogin(userData); // Call the onLogin function to set user in App.js

                navigate('/timesheet');  
            }
        } catch (error) {
            console.error("Login failed", error);
            setError("Login failed. Please check your username and password.");
        }
    };

    return (
        <div className="login-container d-flex justify-content-center align-items-center vh-100">
            <div className="card login-card p-4">
                <h2 className="text-center">Login</h2>
                {error && <p className="text-danger text-center">{error}</p>}
                <form onSubmit={handleSubmit}>
                    <div className="form-group mb-3">
                        <label htmlFor="username">Username</label>
                        <input
                            type="text"
                            id="username"
                            className="form-control"
                            placeholder="Enter your username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required // Make username field required
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label htmlFor="password">Password</label>
                        <div style={{ position: 'relative' }}>
                            <input
                                type={passwordVisible ? 'text' : 'password'}
                                id="password"
                                className="form-control"
                                placeholder="Enter your password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required // Make password field required
                            />
                            <button
                                type="button"
                                onClick={togglePasswordVisibility}
                                style={{
                                    position: 'absolute',
                                    right: '10px',
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    background: 'transparent',
                                    border: 'none',
                                    cursor: 'pointer',
                                    padding: '0',
                                    margin: '0',
                                }}
                            >
                                {passwordVisible ? '🙈' : '👁️'}
                            </button>
                        </div>
                    </div>
                    <button type="submit" className="btn btn-primary w-100">
                        Login
                    </button>
                </form>
            </div>
        </div>
    );
};

export default Login;
